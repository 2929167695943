<template>
    <section class="header-blog-container">
        <div class="header-blog">
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="header-blog__title"
                v-html="pageHeader.title"
            ></div>

            <div
                v-if="pageHeader.subtitle"
                class="header-blog__subtitle"
                v-html="pageHeader.subtitle"
            ></div>

            <div class="header-blog__content">
                <div class="header-blog__content-text">
                    <div class="header-blog__content-title">
                        {{ pageHeader.blog_title }}
                    </div>

                    <!-- eslint-disable vue/no-v-html -->
                    <div v-html="pageHeader.blog_text"></div>
                </div>

                <div
                    v-if="mainHighlight"
                    class="header-blog__content-main"
                    :style="`background-image: url(${mainHighlight.image})`"
                >
                    <Link
                        :href="mainHighlight.url"
                        class="header-blog__content-main-link"
                    >
                        <div
                            class="header-blog__content-main-title"
                            v-html="mainHighlight.title"
                        ></div>
                    </Link>
                </div>
            </div>

            <div
                v-if="otherHighlights.length > 0"
                class="header-blog__content-other"
            >
                <Link
                    v-for="highlight in otherHighlights"
                    :key="highlight.id"
                    :href="highlight.url"
                    class="header-blog__content-other-image"
                    :style="`background-image: url(${highlight.image})`"
                >
                    <div
                        class="header-blog__content-other-title"
                        v-html="highlight.title"
                    ></div>
                </Link>
            </div>
        </div>

        <Media :type="pageHeader.media_type" />
    </section>
</template>

<script setup>
import { computed } from 'vue';
import Media from '../../header-components/media/watson-theme-header-media.vue';

const props = defineProps({
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
});

const mainHighlight = computed(() =>
    props.pageHeader?.highlights?.[0],
);

const otherHighlights = computed(() =>
    props.pageHeader?.highlights ? JSON.parse(JSON.stringify(props.pageHeader.highlights)).splice(1, 3) : [],
);
</script>

<style lang="scss" scoped>
@use 'watson-theme-header-blog';
</style>
