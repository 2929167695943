export const initializeResponsiveText = () => {
    let fs = parseInt(localStorage.fontSize);

    if (!fs) fs = 100;

    document.documentElement.style.setProperty('--base-font-size', fs / 100 + '');
};

export const updateResponsiveText = fs => {
    document.documentElement.style.setProperty('--base-font-size', fs / 100 + '');
};
