<template>
    <div class="error">
        <Header>
            <section class="error__header">
                <div class="error__header-container">
                    <h1 class="error__header-title">
                        {{ translations.title }}
                    </h1>

                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        class="error__information content"
                        v-html="translations.topText"
                    ></div>

                    <div class="error__infographic">
                        <div class="error__404">
                            <div class="error__404-left">
                                4
                            </div>
                            <div>0</div>
                            <div class="error__404-right">
                                4
                            </div>
                        </div>

                        <div
                            v-if="$page.props.settings.image404"
                            class="error__image-container"
                        >
                            <img
                                class="no-shadow"
                                :src="$page.props.settings.image404"
                                alt="404 image"
                            >
                        </div>
                    </div>
                </div>
            </section>
        </Header>

        <section class="error__content">
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="error__bottom-text content"
                v-html="translations.bottomText"
            ></div>
            <div class="error__button-container">
                <Link
                    :href="$page.props.settings.buttonUrl404"
                    class="error__button"
                >
                    {{ translations.button }}
                </Link>
            </div>
        </section>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import Header from '../../layouts/components/Header-index.vue';

const page = usePage();

const translations = page.props.settings.translations.page404;
</script>

<style lang="scss" scoped>
@use "error";
</style>
