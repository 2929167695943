<template>
    <div class="content-header-container">
        <section class="content-header wp-block-group">
            <div
                v-if="breadcrumbs"
                class="content-header__breadcrumbs"
            >
                <ul class="content-header__breadcrumbs-wrapper">
                    <li
                        v-for="(breadcrumb, i) in breadcrumbs"
                        :key="i"
                        class="content-header__breadcrumbs-partial"
                    >
                        <Link
                            v-if="breadcrumb.url"
                            class="content-header__breadcrumbs-link"
                            :href="breadcrumb.url"
                        >
                            {{ breadcrumb.name }}
                        </Link>

                        <span
                            v-else
                            class="content-header__breadcrumbs-text"
                        >
                            {{ breadcrumb.name }}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="content-header__info">
                <Popover class="content-header__text">
                    <PopoverButton
                        as="div"
                        class="content-header__text-button"
                    >
                        Text size
                    </PopoverButton>

                    <PopoverPanel
                        class="content-header__text-popover"
                    >
                        <div
                            class="content-header__text-smaller"
                            :class="{'content-header__text-smaller--active': currentFontSizeKey > 0}"
                            @click="fontSizeDown"
                        >
                            A
                        </div>
                        <div
                            class="content-header__text-size"
                            @click="fontSizeRotate"
                        >
                            {{ fontSizes[currentFontSizeKey] }}%
                        </div>
                        <div
                            class="content-header__text-larger"
                            :class="{'content-header__text-larger--active': currentFontSizeKey < (fontSizes.length -1)}"
                            @click="fontSizeUp"
                        >
                            A
                        </div>
                    </PopoverPanel>
                </Popover>

                <div
                    v-if="lastUpdated"
                    class="content-header__last-updated"
                >
                    {{ $page.props.settings.translations.content.lastUpdated }}: {{ lastUpdated }}
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { updateResponsiveText } from '../../../../js/utils/responsive-text';

const emit = defineEmits(['changeFontSize']);

const props = defineProps({
    contentTitle: {
        type: String,
        required: true,
    },
    lastUpdated: {
        type: String,
        required: false,
        default: null,
    },
    author: {
        type: Object,
        required: false,
        default: null,
    },
    breadcrumbs: {
        type: Object,
        required: false,
        default: null,
    },
});

const fontSizes = ref([
    60,
    80,
    100,
    110,
    125,
    150,
    175,
    200,
]);
const currentFontSizeKey = ref(2);

onMounted(() => {
    if (!props.breadcrumbs) {
        return;
    }

    let fontSize = localStorage.getItem('fontSize');

    if (fontSize && fontSizes.value.includes(parseInt(fontSize))) {
        fontSize = parseInt(fontSize);
        currentFontSizeKey.value = Object.keys(fontSizes.value).find(key => fontSizes.value[key] === fontSize);
        emit('changeFontSize', fontSize);
    }
});

function fontSizeDown() {
    if (currentFontSizeKey.value <= 0) {
        currentFontSizeKey.value = 0;
        return;
    }

    currentFontSizeKey.value--;

    saveAndEmitfontSize();
}

function fontSizeRotate() {
    if (currentFontSizeKey.value >= (fontSizes.value.length - 1)) {
        currentFontSizeKey.value = 0;

        saveAndEmitfontSize();
        return;
    }

    currentFontSizeKey.value++;

    saveAndEmitfontSize();
}

function fontSizeUp() {
    if (currentFontSizeKey.value >= (fontSizes.value.length - 1)) {
        currentFontSizeKey.value = fontSizes.value.length - 1;
        return;
    }

    currentFontSizeKey.value++;

    saveAndEmitfontSize();
}

function saveAndEmitfontSize() {
    const fontSize = fontSizes.value[currentFontSizeKey.value];
    localStorage.setItem('fontSize', String(fontSize));

    emit('changeFontSize', fontSize);
    updateResponsiveText(fontSize);
}
</script>

<style lang="scss" scoped>
@use 'watson-theme-content-header';
</style>
