<template>
    <div
        v-if="$page.props.settings.becomeAPartnerUrl"
        class="become-a-partner-container"
    >
        <div class="become-a-partner__title">
            {{ $page.props.settings.translations.becomePartner.title }}
        </div>
        <div class="become-a-partner__inner">
            <div class="become-a-partner__top">
                <p class="become-a-partner__text">
                    {{ $page.props.settings.translations.becomePartner.text }}
                </p>
                <img
                    v-if="$page.props.settings.becomeAPartnerImage"
                    :src="$page.props.settings.becomeAPartnerImage"
                    class="become-a-partner__image"
                    :alt="$page.props.settings.translations.becomePartner.title"
                    loading="lazy"
                >
            </div>

            <div>
                <a
                    :href="$page.props.settings.becomeAPartnerUrl"
                    class="become-a-partner__button"
                >
                    {{ $page.props.settings.translations.becomePartner.button }}
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use 'watson-theme-become-a-partner';
</style>
