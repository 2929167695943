<template>
    <div class="related-articles">
        <div class="related-articles__title">
            {{ $page.props.settings.translations.relatedArticles.title }}
        </div>

        <div class="related-articles__articles">
            <div
                v-for="article in articles"
                :key="article.id"
                class="related-articles__article"
            >
                <Link
                    class="related-articles__article-inner"
                    :href="article.url"
                >
                    <div
                        v-if="article.image"
                        class="related-articles__article-image-wrapper"
                    >
                        <img
                            :src="article.image.replace('format=auto', 'w=152,h=152,fit=cover,f=auto')"
                            :alt="article.title"
                            class="related-articles__article-image"
                            loading="lazy"
                        >
                    </div>

                    <div
                        v-else
                        class="related-articles__article-brand-wrapper"
                    >
                        <img
                            :src="brand.logo.url"
                            :width="brand.logo?.width > 1 ? brand.logo.width : null"
                            :height="brand.logo?.height > 1 ? brand.logo.height : null"
                            :alt="article.title"
                            class="related-articles__article-image"
                            loading="lazy"
                        >
                    </div>

                    <div class="related-articles__article-title">
                        {{ article.title }}
                    </div>
                </Link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const brand = page.props.brand;

defineProps({
    articles: {
        type: Array,
        required: false,
        default: () => [],
    },
});
</script>

<style lang="scss" scoped>
@use 'watson-theme-related-articles';
</style>
