<template>
    <AppHead
        :title="title"
        :yoast-head="yoastHead"
    />

    <div class="content">
        <WatsonOrganismContent
            :content="content"
        />
    </div>
</template>

<script setup>
import AppHead from '../../layouts/components/AppHead.vue';

defineProps({
    title: {
        type: String,
        required: true,
    },

    yoastHead: {
        type: String,
        required: false,
        default: '',
    },

    content: {
        type: Object,
        required: true,
    },
});
</script>

<script>
import CleanLayout from '../../../../js/Layouts/Clean-index.vue';

export default {
    layout: CleanLayout,
};
</script>
