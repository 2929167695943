<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <Popover
        v-slot="{ open }"
        as="div"
        class="flex items-center md:hidden text-lg"
    >
        <PopoverButton
            as="div"
            class="flex flex-col items-center text-white cursor-pointer"
        >
            <svg
                class="h-8 w-10 -mb-3.5 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18.6 18.6"
            >
                <path
                    d="M18.3,0h-18C0.1,0,0,0.1,0,0.3c0-0.1,0,0.4,0,0.4C0,0.9,0.1,1,0.3,1h18c0.2,0,0.3-0.1,0.3-0.3c0,0.1,0-0.4,0-0.4C18.6,0.1,18.5,0,18.3,0z"
                />
                <path
                    d="M18.6,4.8c0,0,0,0.5,0,0.4c0,0.2-0.1,0.3-0.3,0.3h-18C0.1,5.5,0,5.4,0,5.2c0,0,0-0.5,0-0.4c0-0.2,0.1-0.3,0.3-0.3h18C18.5,4.5,18.6,4.6,18.6,4.8z"
                />
                <path
                    d="M18.3,9h-18C0.1,9,0,9.1,0,9.3c0-0.1,0,0.4,0,0.4C0,9.9,0.1,10,0.3,10h18c0.2,0,0.3-0.1,0.3-0.3c0,0.1,0-0.4,0-0.4C18.6,9.1,18.5,9,18.3,9z"
                />
            </svg>
            <span class="text-sm">
                {{ translations.header.menu }}
            </span>
        </PopoverButton>
        <PopoverOverlay class="z-40 fixed inset-0 bg-black opacity-50" />

        <Transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <PopoverPanel
                v-slot="{ close }"
                as="div"
                :open="open"
                class="menu z-50 absolute top-0 left-0 pb-10 w-screen text-white whitespace-nowrap"
            >
                <div class="px-4 bg-base-400 flex py-3.5 w-full justify-between items-center">
                    <div>
                        <Link
                            href="/"
                            :onBefore="close"
                        >
                            <img
                                :src="brand.logo.url"
                                :width="brand.logo?.width > 1 ? brand.logo.width : null"
                                :height="brand.logo?.height > 1 ? brand.logo.height : null"
                                class="h-[2.2rem] md:h-[35px]"
                                :alt="brand.name"
                            >
                        </Link>
                    </div>
                    <div
                        class="cursor-pointer"
                        @click="close"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-9 w-9"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="1"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </div>

                <div class="flex flex-col xs:flex-row justify-between">
                    <div class="w-full">
                        <div
                            v-for="item in headerMenu.items"
                            :key="item.url"
                            class="bg-base-400"
                        >
                            <Disclosure
                                v-if="item.subMenu"
                                v-slot="{ open: subOpen }"
                                as="div"
                            >
                                <div
                                    :class="subOpen ? 'bg-gradient-to-r from-base-600/40 to-base-400' : ''"
                                >
                                    <DisclosureButton
                                        class="flex w-full items-center justify-between xs:justify-start p-4 text-left cursor-pointer hover:opacity-70 ease-out duration-300"
                                        :onBefore="close"
                                        :class="[
                                            isActive(item, $page.props.url) ? 'font-bold': ''
                                        ]"
                                    >
                                        <div>
                                            {{ item.name }}
                                        </div>

                                        <svg
                                            :class="subOpen ? 'rotate-180 transform' : ''"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="h-5 w-5 opacity-70 xs:ml-2"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </DisclosureButton>
                                    <DisclosurePanel class="flex flex-col">
                                        <template v-if="item.url && item.url.length > 0">
                                            <Link
                                                :href="item.url"
                                                :onBefore="close"
                                                class="px-8 py-4 hover:opacity-70 ease-out duration-300"
                                                :class="[
                                                    isActive(item, $page.props.url) ? 'font-bold': ''
                                                ]"
                                            >
                                                {{ translations.header.menuOverview }}
                                            </Link>
                                        </template>

                                        <template
                                            v-for="subItem in item.subMenu"
                                            :key="subItem.url"
                                        >
                                            <Link
                                                :href="subItem.url"
                                                :onBefore="close"
                                                class="px-8 py-4 hover:opacity-70 ease-out duration-300"
                                                :class="[
                                                    isActive(subItem, $page.props.url) ? 'font-bold': ''
                                                ]"
                                            >
                                                {{ subItem.name }}
                                            </Link>

                                            <Link
                                                v-for="subSubItem in subItem.subMenu"
                                                :key="subSubItem.url"
                                                :href="subSubItem.url"
                                                :onBefore="close"
                                                class="flex items-center px-8 py-4 hover:opacity-70 ease-out duration-300"
                                                :class="[
                                                    isActive(subSubItem, $page.props.url) ? 'font-bold': ''
                                                ]"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    class="opacity-70 mr-1 w-5 h-5"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>

                                                {{ subSubItem.name }}
                                            </Link>
                                        </template>
                                    </DisclosurePanel>
                                </div>
                            </Disclosure>
                            <Link
                                v-else
                                :href="item.url"
                                :onBefore="close"
                                class="block p-4 hover:opacity-70 ease-out duration-300"
                                :class="[
                                    isActive(item, $page.props.url) ? 'font-bold': ''
                                ]"
                            >
                                {{ item.name }}
                            </Link>
                        </div>
                    </div>

                    <div class="flex flex-col bg-base-400">
                        <a
                            v-if="brand.telephone"
                            :href="`tel:${brand.countryCode}${brand.telephone.replace(/\s|-/g, '').replace(/^0+/, '')}`"
                            class="phone-number p-4 opacity-70 hover:opacity-100 ease-out duration-300"
                        >
                            {{ brand.telephone }}
                        </a>
                        <Link
                            v-if="$page.props.settings.becomeAPartnerUrl"
                            :href="$page.props.settings.becomeAPartnerUrl"
                            :onBefore="close"
                            class="p-4 hover:opacity-100 ease-out duration-300"
                            :class="[
                                isActive({url: $page.props.settings.becomeAPartnerUrl}, $page.props.url) ? 'opacity-100 font-bold': 'opacity-70'
                            ]"
                        >
                            {{ translations.becomePartner.title }}
                        </Link>
                        <Link
                            v-if="$page.props.settings.covidUrl"
                            :href="$page.props.settings.covidUrl"
                            :onBefore="close"
                            class="p-4 hover:opacity-100 ease-out duration-300"
                            :class="[
                                isActive({url: $page.props.settings.covidUrl}, $page.props.url) ? 'opacity-100 font-bold': 'opacity-70'
                            ]"
                        >
                            {{ translations.header.covid }}
                        </Link>
                    </div>
                </div>
            </PopoverPanel>
        </Transition>
    </Popover>

    <div class="hidden md:flex text-white space-x-7">
        <HeaderItem
            v-for="item in headerMenu.items"
            :key="item.url"
            :item="item"
        />
    </div>
</template>

<script setup>
import {
    Popover,
    PopoverOverlay,
    PopoverButton,
    PopoverPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue';

import { usePage, Link } from '@inertiajs/vue3';
import { isActive } from '../../mixins/menu';
import HeaderItem from './menu/HeaderItem.vue';

const page = usePage();

const headerMenu = page.props.menus?.headerMenu || { items: [], name: '' };
const brand = page.props.brand;
const translations = page.props.settings.translations;
</script>

<style scoped>
.menu::after {
  content: var(--menu-bottom);
  display: block;
  width: 100%;
  margin-top: -1px;
}
</style>
