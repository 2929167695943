<template>
    <AppHead
        :title="title"
        :yoast-head="yoastHead"
    />

    <Header>
        <PageHeader :page-header="pageHeader" />
    </Header>

    <section class="blog">
        <ContentHeader
            v-if="title && lastUpdated"
            :content-title="title"
            :last-updated="lastUpdated"
            :breadcrumbs="breadcrumbs"
        />

        <div class="blog__content-wrapper">
            <div class="blog__content">
                <h1 class="blog__content-title">
                    {{ blogTitle }}{{ year ? ` - ${year}`:'' }}{{ month ? ` / ${month}`:'' }}
                </h1>

                <div class="blog__content-search">
                    <Search :categories="categories" />
                </div>

                <div>
                    <Categories :categories="categories" />
                </div>

                <MoreOnThisTopic
                    v-if="!hideMoreOnThisTopic && subPages.length > 0"
                    :pages="subPages"
                />
            </div>

            <div class="blog__sidebar">
                <ItsThatEasy />
                <BecomeAPartner />
            </div>
        </div>
    </section>
</template>

<script setup>
import { onMounted } from 'vue';
import Search from './components/search/watson-theme-search.vue';
import Categories from './components/categories/watson-theme-categories.vue';
import ItsThatEasy from '../components/its-that-easy/watson-theme-its-that-easy.vue';
import MoreOnThisTopic from '../components/more-on-this-topic/watson-theme-more-on-this-topic.vue';
import BecomeAPartner from '../components/become-a-partner/become-a-partner.vue';
import PageHeader from '../content/headers/headers-index.vue';
import AppHead from '../../layouts/components/AppHead.vue';
import ContentHeader from '../content/components/content-header/watson-theme-content-header.vue';
import Header from '../../layouts/components/Header-index.vue';
import { initializeResponsiveText } from '../../js/utils/responsive-text';

defineProps({
    title: {
        type: String,
        required: true,
    },
    blogTitle: {
        type: String,
        required: true,
    },
    yoastHead: {
        type: String,
        required: false,
        default: '',
    },
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
    categories: {
        type: Array,
        required: true,
    },
    lastUpdated: {
        type: String,
        required: false,
        default: null,
    },
    breadcrumbs: {
        type: Object,
        required: false,
        default: null,
    },
    year: {
        type: String,
        required: false,
        default: null,
    },
    month: {
        type: String,
        required: false,
        default: null,
    },
    subPages: {
        type: Array,
        required: false,
        default: () => [],
    },
    hideMoreOnThisTopic: {
        type: Boolean,
        required: false,
        default: false,
    },
});

onMounted(() => {
    initializeResponsiveText();
});
</script>

<style lang="scss" scoped>
@use "blog-index";
</style>
