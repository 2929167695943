<template>
    <section
        class="header-job-container"
        :style="`--background-image: url('${pageHeader.background_image_job}');`"
    >
        <div class="header-job">
            <img
                v-if="pageHeader.hide_arrow === false"
                src="../../icons/header-arrow.png"
                class="header-job__arrow"
                alt=""
            >

            <div class="header-job__titles">
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="pageHeader.hide_title === false"
                    class="header-job__title"
                    v-html="pageHeader.title"
                ></div>
                <div
                    v-if="pageHeader.subtitle"
                    class="header-job__subtitle"
                    :class="pageHeader.big_subtitle === true ? 'header-job__subtitle--big' : ''"
                    v-html="pageHeader.subtitle"
                ></div>
            </div>

            <div class="header-job__input-container">
                <select
                    v-model="selectedType"
                    class="header-job__select"
                    @change="updateJob"
                >
                    <option
                        v-if="!selectedType"
                        :value="null"
                        disabled
                        selected
                    >
                        Type
                    </option>
                    <option
                        v-for="type in types"
                        :key="type.value"
                        :value="type.value"
                    >
                        {{ type.label }}
                    </option>
                </select>

                <select
                    v-model="selectedJob"
                    class="header-job__select"
                >
                    <option
                        v-if="jobs.length === 0"
                        :value="null"
                        disabled
                        selected
                    >
                        Job
                    </option>
                    <option
                        v-for="(job, i) in jobs"
                        :key="`${i}_${job.value}`"
                        :value="job.value"
                    >
                        {{ job.label }}
                    </option>
                </select>
                <OrangeButton
                    text="Get Free Quotes"
                    :disabled="!selectedJob"
                    @clicked="redirect()"
                />
            </div>

            <div class="header-job__usps">
                <template
                    v-for="(usp, i) in pageHeader.usp_items"
                    :key="`page-header-usp-${i}`"
                >
                    <div class="header-job__usp">
                        <img
                            src="../../icons/usp-check.svg"
                            class="header-job__usp-image"
                            alt=""
                        >
                        {{ usp.usp_item }}
                    </div>
                </template>
            </div>

            <div class="header-job__reviews">
                <img
                    v-if="pageHeader.trust_pilot_status === false"
                    src="../../icons/trustpilot-reviews.svg"
                    alt="Trustpilot reviews"
                >

                <div
                    v-if="pageHeader.hide_mentioned === false"
                >
                    <Media
                        :type="pageHeader.media_type"
                        :inline="true"
                        :without-background="true"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { computed, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import Media from '../../header-components/media/watson-theme-header-media.vue';
import { default as OrangeButton } from '../../../../../components/buttons/orange/watson-theme-orange-button.vue';

const selectedType = ref(null);
const selectedJob = ref(null);

const props = defineProps({
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
});

const jobTypes = props?.pageHeader['job_types'] ?? [];

const types = [];
jobTypes.forEach((jobType, index) => {
    types.push({
        value: index,
        label: jobType['page_header_job_types_type'],
    });
});

const jobs = computed(() => {
    const jobOptions = [];

    if (selectedType.value === null) {
        return jobOptions;
    }

    const typeJobs = jobTypes[selectedType.value]['page_header_job_types_jobs'] ?? [];
    typeJobs.forEach(job => {
        jobOptions.push({
            value: job['page_header_job_types_jobs_redirect'],
            label: job['page_header_job_types_jobs_job'],
        });
    });

    return jobOptions;
});

function updateJob() {
    selectedJob.value = jobs.value[0].value ?? null;
}

function redirect() {
    if (!selectedJob.value) {
        return;
    }

    router.visit(selectedJob.value);
}
</script>

<style lang="scss" scoped>
@use "watson-theme-header-job";
</style>
