export function isActive(item, pageUrl) {
    if (item.subMenu) {
        for (const subMenu of item.subMenu) {
            if (isActive(subMenu, pageUrl)) {
                return true;
            }
        }
        return item.url === pageUrl;
    }

    return item.url === pageUrl;
}
