<template>
    <Disclosure
        v-slot="{ open }"
        :default-open="true"
        as="div"
        class="guide-container"
    >
        <DisclosureButton class="guide">
            <span class="guide__title">
                {{ $page.props.settings.translations.guide.title }}
            </span>

            <svg
                :class="open ? '' : 'guide__icon--closed'"
                xmlns="http://www.w3.org/2000/svg"
                class="guide__icon "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                />
            </svg>
        </DisclosureButton>

        <Transition
            enter-active-class="guide__transition guide__transition--active"
            enter-from-class="guide__transition guide__transition--enter-from"
            enter-to-class="guide__transition guide__transition--enter-to"
            leave-active-class="guide__transition guide__transition--leave"
            leave-from-class="guide__transition guide__transition--leave-from"
            leave-to-class="guide__transition guide__transition--leave-to"
        >
            <DisclosurePanel class="guide__content">
                <ul class="guide__content-items" :class="links.length > 3 && ctaShown ? toggleClass : ''">
                    <li
                        v-for="link in links"
                        :key="link.link"
                        class="guide__content-item"
                    >
                        <!-- eslint-disable vue/no-v-html -->
                        <a
                            class="guide__content-item-link"
                            :class="{'active': activeLink === link.link}"
                            :href="`#${link.link}`"
                            v-html="link.name?.replace(/^_/, '')"
                        ></a>
                    </li>
                </ul>

                <button v-if="links.length > 3 && ctaShown && open" class="guide__toggle" @click="toggleGuide">
                    <p v-if="guideClosed">
                        {{ $page.props.settings.translations.guide.open }}
                    </p>
                    <p v-else>
                        {{ $page.props.settings.translations.guide.close }}
                    </p>
                </button>
            </DisclosurePanel>
        </Transition>
    </Disclosure>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const props = defineProps({
    links: {
        type: Array,
        required: true,
    },
    ctaShown: {
        type: Boolean,
        required: false,
        default: false,
    },
});

onMounted(() => {
    observeLinks();
});

const activeLink = ref(props.links[0].link);
const guideClosed = ref(true);
const toggleClass = ref('guide__content-items--large-closed');

let linkObserver = null;

function toggleGuide() {
    if (guideClosed.value === true) {
        toggleClass.value = '';
        guideClosed.value = false;
    } else {
        toggleClass.value = 'guide__content-items--large-closed';
        guideClosed.value = true;
    }
}

function observeLinks() {
    const options = {
        threshold: 0,
        rootMargin: '0px 0px -70% 0px',
    };
    linkObserver = new IntersectionObserver(linkObserverHandler, options);

    props.links.forEach(link => {
        const element = document.getElementById(link.link);
        if (element) {
            linkObserver.observe(element);
        }
    });
}

function linkObserverHandler(entries) {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            activeLink.value = entry.target.id;
        } else if (activeLink.value === entry.target.id) {
            if (entry.target.getBoundingClientRect().top > 0) {
                const key = Object.keys(props.links).find(key => props.links[key].link === activeLink.value);
                activeLink.value = props.links[key - 1] ? props.links[key - 1].link : props.links[key].link;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@use 'watson-theme-guide';

a::before {
  background-image: var(--guide-arrow);
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 0;
  left: -19px;
  position: absolute;
  top: 1.6px;
  transition: 0.3s ease-out;
  width: 16px;
  opacity: 0;
}

a.active::before {
  height: 12px;
  opacity: 1;
}
</style>
