<template>
    <div class="video-modal">
        <div class="video-modal__button-container">
            <button
                class="video-modal__button"
                type="button"
                @click="openModal"
            >
                {{ pageHeader.video_button }}
            </button>
        </div>

        <TransitionRoot
            appear
            :show="isOpen"
            as="template"
        >
            <Dialog
                as="div"
                class="video-modal__dialog"
                @close="closeModal"
            >
                <TransitionChild
                    as="template"
                    enter="video-modal__enter"
                    enter-from="video-modal__enter-from"
                    enter-to="video-modal__enter-to"
                    leave="video-modal__leave"
                    leave-from="video-modal__leave-from"
                    leave-to="video-modal__leave-to"
                >
                    <div class="video-modal__background"></div>
                </TransitionChild>

                <div class="video-modal__content">
                    <div
                        class="video-modal__content-inner"
                    >
                        <TransitionChild
                            as="template"
                            enter="video-modal__enter"
                            enter-from="video-modal__enter-from video-modal__enter-from--scale"
                            enter-to="video-modal__enter-to video-modal__enter-to--scale"
                            leave="video-modal__leave"
                            leave-from="video-modal__leave-from video-modal__leave-from--scale"
                            leave-to="video-modal__leave-to video-modal__leave-to--scale"
                        >
                            <DialogPanel
                                class="video-modal__panel"
                            >
                                <iframe
                                    class="video-modal__video"
                                    :src="videoUrl"
                                    :title="pageHeader.video_title"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>

                                <button
                                    class="video-modal__exit-button"
                                    @click="closeModal"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        class="video-modal__exit-icon"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.28 3.22a.75.75 0 00-1.06 1.06L8.94 10l-5.72 5.72a.75.75 0 101.06 1.06L10 11.06l5.72 5.72a.75.75 0 101.06-1.06L11.06 10l5.72-5.72a.75.75 0 00-1.06-1.06L10 8.94 4.28 3.22z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-html="`<script type=&quot;application/ld+json\&quot;>${JSON.stringify(structuredData)}</script>`"
        ></div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps({
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
});

const videoUrl = 'https://www.youtube-nocookie.com/embed/' + props.pageHeader.video + '?autoplay=1&rel=0';

const isOpen = ref(false);

const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    'name': props.pageHeader.video_title,
    'description': props.pageHeader.video_description,
    'duration': props.pageHeader.video_duration,
    'uploadDate': props.pageHeader.video_upload,
    'embedUrl': videoUrl,
};

if (props.pageHeader.video_transcript) {
    structuredData.transcript = ref(props.pageHeader.video_transcript);
}

function closeModal() {
    isOpen.value = false;
}

function openModal() {
    isOpen.value = true;
}
</script>

<style lang="scss" scoped>
@use 'watson-theme-video-modal';
</style>
