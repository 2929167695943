<template>
    <div class="category">
        <div class="category__list">
            <Link
                v-for="article in showArticles"
                :key="article.id"
                :href="article.url"
                class="category__item"
                :style="`background-image: url(${article.image})`"
            >
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="category__item-title"
                    v-html="article.title"
                ></div>
            </Link>
        </div>

        <div class="category__show-more">
            <div class="category__show-more-border"></div>

            <div
                v-show="showArticles.length < articles.length"
                class="category__show-more-text"
            >
                <div
                    class="category__show-more-text-inner"
                    @click="pageNumber++"
                >
                    {{ $page.props.settings.translations.blog.more }}
                </div>
            </div>

            <div class="category__show-more-border"></div>

            <div
                v-show="pageNumber > 1"
                class="category__close"
                @click="pageNumber = 1"
            >
                {{ $page.props.settings.translations.blog.close }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const perPage = ref(4);
const pageNumber = ref(1);

const props = defineProps({
    articles: {
        type: Array,
        required: true,
    },
});

onMounted(() => {
    const isDesktop = window.matchMedia('screen and (min-width: 575px)').matches;

    if (isDesktop) {
        perPage.value = 3;
    }
});

const showArticles = computed(() =>
    props.articles.slice(0, pageNumber.value * perPage.value),
);
</script>

<style lang="scss" scoped>
@use "watson-theme-category";
</style>
