<template>
    <div class="search">
        <h2 class="search__title">
            {{ $page.props.settings.translations.blog.search }}
        </h2>

        <div class="search__inner">
            <Combobox
                v-slot="{ open }"
                v-model="selectedArticle"
                @update:model-value="redirect"
            >
                <div class="search__inner-wrapper">
                    <div
                        class="search__input-wrapper"
                    >
                        <ComboboxInput
                            class="search__input"
                            placeholder="Type to search for an article title"
                            :value="query"
                            @change="query = $event.target.value"
                        />
                    </div>
                    <TransitionRoot
                        leave="search__transition--leave"
                        leave-from="search__transition--leave-from"
                        leave-to="search__transition--leave-to"
                        :show="query !== '' && (open || manualOpen)"
                        @before-leave="manualOpen = query.value !== ''"
                    >
                        <ComboboxOptions
                            static
                            class="search__options"
                        >
                            <div
                                v-if="filteredArticles.length === 0 && query !== ''"
                                class="search__no-match"
                            >
                                {{ $page.props.settings.translations.blog.noMatch }}
                            </div>

                            <ComboboxOption :value="null" />

                            <ComboboxOption
                                v-for="article in filteredArticles"
                                :key="article.id"
                                v-slot="{ active }"
                                as="template"
                                :value="article"
                            >
                                <li
                                    class="search__options-item"
                                    :class="{
                                        'search__options-item--active': active,
                                    }"
                                >
                                    <span class="search__options-item-title">
                                        {{ article.title }}
                                    </span>
                                </li>
                            </ComboboxOption>
                        </ComboboxOptions>
                    </TransitionRoot>
                </div>
            </Combobox>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, TransitionRoot } from '@headlessui/vue';
import { router } from '@inertiajs/vue3';

const selectedArticle = ref(null);
const manualOpen = ref(false);
const query = ref('');

const props = defineProps({
    categories: {
        type: Array,
        required: true,
    },
});

let articles = [];
props.categories.forEach(category => {
    articles.push(...category.articles);
});
articles = articles.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
        return acc.concat([current]);
    } else {
        return acc;
    }
}, []);

const filteredArticles = computed(() =>
    query.value === ''
        ? articles
        : articles.filter(article =>
            article.title
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.value.toLowerCase().replace(/\s+/g, '')),
        ),
);

function redirect(article) {
    if (!article) {
        manualOpen.value = true;
        return;
    }

    manualOpen.value = false;

    router.visit(article.url);
}
</script>

<style lang="scss" scoped>
@use "watson-theme-search";
</style>
