<template>
    <TransitionRoot
        as="template"
        :show="mounted && isModalOpen"
    >
        <Dialog
            as="div"
            class="phone-verified"
            @close="isModalOpen = false"
        >
            <TransitionChild
                as="template"
                enter="phone-verified__transition--enter"
                enter-from="phone-verified__transition--enter-from"
                enter-to="phone-verified__transition--enter-to"
                leave="phone-verified__transition--leave"
                leave-from="phone-verified__transition--leave-from"
                leave-to="phone-verified__transition--leave-to"
            >
                <div class="phone-verified__thing"></div>
            </TransitionChild>

            <div
                v-if="isModalOpen"
                class="phone-verified__modal"
            >
                <div class="phone-verified__modal-inner">
                    <TransitionChild
                        as="template"
                        enter="phone-verified__modal-transition--enter"
                        enter-from="phone-verified__modal-transition--enter-from"
                        enter-to="phone-verified__modal-transition--enter-to"
                        leave="phone-verified__modal-transition--leave"
                        leave-from="phone-verified__modal-transition--leave-from"
                        leave-to="phone-verified__modal-transition--leave-to"
                    >
                        <DialogPanel class="phone-verified__panel">
                            <div class="phone-verified__panel-button">
                                <button @click="isModalOpen = false">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="phone-verified__panel-button-icon"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <div class="phone-verified__panel-content">
                                <DialogTitle class="phone-verified__panel-title">
                                    {{ $page.props.settings.translations.verifyModal.title }}
                                </DialogTitle>

                                <div class="phone-verified__panel-content-text">
                                    <div class="phone-verified__panel-text-wrapper">
                                        <!-- eslint-disable vue/no-v-html -->
                                        <div
                                            class="phone-verified__panel-text"
                                            v-html="$page.props.settings.translations.verifyModal.text"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { onMounted, ref } from 'vue';

const mounted = ref(false);
const isModalOpen = ref(false);

const props = defineProps({
    verified: {
        type: Boolean,
        required: false,
        default: false,
    },
});

onMounted(() => {
    mounted.value = true;
    isModalOpen.value = props.verified;
});
</script>
