<template>
    <AppHead
        :title="title"
        :yoast-head="yoastHead"
    />

    <Header
        :page-header="pageHeader"
        :background-image="headerBackgroundImage"
    >
        <template #top-header>
            <TopBanner
                v-if="topBanner"
                v-bind="topBanner"
            />
        </template>

        <PageHeader :page-header="pageHeader" />
    </Header>

    <section
        class="content"
        :class="{
            'content-and-sidebar container': sidebar,
        }"
    >
        <ContentHeader
            v-if="!hideContentHeader && contentTitle"
            :content-title="contentTitle"
            :last-updated="lastUpdated"
            :author="authors[0]"
            :breadcrumbs="breadcrumbs"
            @change-font-size="changeFontSize"
        />

        <div class="flex flex-col md:flex-row mb-8">
            <div
                ref="contentRef"
                class="mb-8 space-y-4"
                :class="{
                    'md:w-2/3 md:mr-4': sidebar,
                    'w-full': !sidebar,
                }"
            >
                <div class="w-full">
                    <WatsonOrganismContent
                        classes="body-content"
                        :content="content"
                    />
                </div>

                <div
                    class="space-y-4"
                    :class="{
                        'container': !sidebar,
                    }"
                >
                    <ContentAuthor
                        v-if="authors.length"
                        :author="author"
                        :contributors="contributors"
                        :expert-reviewers="expertReviewers"
                        :editors="editors"
                        :on-content-page="true"
                    />
                    <RelatedArticles
                        v-if="relatedArticles.length > 0"
                        :articles="relatedArticles"
                    />
                    <MoreOnThisTopic
                        v-if="!hideMoreOnThisTopic && subPages.length > 0"
                        :pages="subPages"
                    />
                </div>
            </div>

            <WatsonThemeContentSidebar
                v-if="sidebar"
                :hide-become-a-partner="hideBecomeAPartner"
                :hide-its-that-easy="hideItsThatEasy"
                :show-cta="page?.props?.showSideBarCta"
                :top-pages="topPages"
                :links="links"
                :post-types="postTypes"
            />
        </div>
    </section>

    <PhoneNumberVerifiedModal :verified="phoneNumberVerified" />
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { usePage, router } from '@inertiajs/vue3';
import { storeToRefs } from 'pinia';
import AppHead from '../../layouts/components/AppHead.vue';
import TopBanner from '../../layouts/components/top-banner/top-banner.vue';
import Header from '../../layouts/components/Header-index.vue';
import ContentHeader from './components/content-header/watson-theme-content-header.vue';
import PageHeader from './headers/headers-index.vue';
import MoreOnThisTopic from '../components/more-on-this-topic/watson-theme-more-on-this-topic.vue';
import RelatedArticles from '../components/related-articles/watson-theme-related-articles.vue';
import PhoneNumberVerifiedModal from '../components/phone-number-verified-modal/watson-theme-phone-number-verified-modal.vue';
import ContentAuthor from '@organisms/content-author/watson-organism-content-author.vue';
import { initializeResponsiveText } from '../../js/utils/responsive-text';
import { useTableOfContentsStore } from '../../components/table-of-contents/watson-theme-table-of-contents';
import WatsonThemeContentSidebar from './components/content-sidebar/watson-theme-content-sidebar.vue';

onMounted(() => {
    initializeResponsiveText();
    document.querySelectorAll('a').forEach(function (e) {
        e.addEventListener('click', clickAction, false);
    });
});

onBeforeUnmount(() => {
    document.querySelectorAll('a').forEach(function (e) {
        e.removeEventListener('click', clickAction);
    });
});

const props = defineProps({
    template: {
        type: String,
        required: false,
        default: '',
    },
    title: {
        type: String,
        required: true,
    },
    yoastHead: {
        type: String,
        required: false,
        default: '',
    },
    contentTitle: {
        type: String,
        required: false,
        default: null,
    },
    content: {
        type: [Array, Object],
        required: true,
    },
    lastUpdated: {
        type: String,
        required: false,
        default: null,
    },
    topBanner: {
        type: Object,
        required: false,
        default: null,
    },
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
    breadcrumbs: {
        type: Object,
        required: false,
        default: null,
    },
    relatedArticles: {
        type: Array,
        required: false,
        default: () => [],
    },
    subPages: {
        type: Array,
        required: false,
        default: () => [],
    },
    topPages: {
        type: Array,
        required: false,
        default: () => [],
    },
    authors: {
        type: Array,
        required: false,
        default: () => [],
    },
    guideLinks: {
        type: Array,
        required: false,
        default: () => [],
    },
    hideMoreOnThisTopic: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideItsThatEasy: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideBecomeAPartner: {
        type: Boolean,
        required: false,
        default: false,
    },
    hideContentHeader: {
        type: Boolean,
        required: false,
        default: false,
    },
    phoneNumberVerified: {
        type: Boolean,
        required: false,
        default: false,
    },

    postTypes: {
        type: Object,
        required: false,
        default: () => {},
    },
});

const page = usePage();
const tableOfContents = useTableOfContentsStore(page.props.url);
const { links } = storeToRefs(tableOfContents);
const headerBackgroundImage = props?.pageHeader?.background_image ?? '';

const author = ref(props.authors[0]);
const contributors = ref(JSON.parse(JSON.stringify(props.authors)).splice(1).filter(author => author.type === 'contributor'));
const expertReviewers = ref(JSON.parse(JSON.stringify(props.authors)).splice(1).filter(author => author.type === 'expert'));
const editors = ref(JSON.parse(JSON.stringify(props.authors)).splice(1).filter(author => author.type === 'editor'));

const contentRef = ref(null);

const sidebar = props.template === 'content-and-sidebar.php' || props.template === 'author.php';

function changeFontSize(fontSize) {
    document.body.style.fontSize = fontSize + '%';
}

function clickAction(event) {
    const target = event.target.closest('a');
    if (!target || target.tagName !== 'A') {
        return;
    }
    if (target.href) {
        event.preventDefault();
        if (target.target && target.target === '_blank') {
            window.open(target.href);
            return;
        }
        if (window.location.hostname === target.hostname) {
            if (window.location.pathname === target.pathname && target.hash) {
                window.location.hash = target.hash;
                return;
            }
            router.visit(target.href);
            return;
        }
        window.open(target.href);
    }
}
</script>
