<template>
    <div
        :class="[
            'trustpilot-header-widget',
            { 'trustpilot-header-widget--hidden': hide }
        ]"
    >
        <!-- TrustBox widget -->
        <div
            class="trustpilot-widget"
            :data-locale="language"
            :data-template-id="templateId"
            :data-businessunit-id="businessId"
            data-style-height="20px"
            data-style-width="100%"
        >
            <a :href="url" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    businessId: {
        type: String,
        required: false,
        default: '5c814c5a2a223e0001174b05',
    },
    url: {
        type: String,
        required: false,
        default: 'https://www.trustpilot.com/review/greenmatch.co.uk',
    },
    templateId: {
        type: String,
        required: false,
        default: '5419b6ffb0d04a076446a9af',
    },
    hide: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const language = ref('en-GB');

onMounted(() => {
    language.value = document.documentElement.lang;

    const trustpilotScript = document.createElement('script');
    trustpilotScript.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    trustpilotScript.setAttribute('async', '');

    document.head.appendChild(trustpilotScript);
});
</script>

<style lang="scss" scoped>
@use "watson-theme-header-trustpilot-widget";
</style>
