<template>
    <section
        id="form"
        class="header-script-container"
    >
        <div class="header-script">
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="header-script__title"
                :class="[pageHeader.subtitle ? 'header-script__title--active-sub' : '']"
                v-html="pageHeader.title"
            ></div>
            <div
                v-if="pageHeader.subtitle"
                class="header-script__subtitle"
                v-html="pageHeader.subtitle"
            ></div>
            <div class="header-script__inner">
                <div class="header-script__details">
                    <div
                        class="header-script__sellingpoints"
                    >
                        <div
                            v-for="(sellingPoint, index) in pageHeader.selling_points"
                            :key="index"
                            class="header-script__sellingpoints-inner"
                        >
                            <div class="header-script__image">
                                <img
                                    :src="sellingPoint.icon.url"
                                    :alt="sellingPoint.headline"
                                    loading="lazy"
                                    :width="sellingPoint.image?.width > 1 ? sellingPoint.image.width : null"
                                    :height="sellingPoint.image?.height > 1 ? sellingPoint.image.height : null"
                                >
                            </div>
                            <div class="header-script__text">
                                <span class="header-script__popper-container">
                                    <Popper
                                        placement="top"
                                        arrow
                                    >
                                        <button class="header-script__popper-button">{{ sellingPoint.headline }}</button>
                                        <template #content>
                                            <div class="header-script__popper-description">{{ sellingPoint.description }}</div>
                                        </template>
                                    </Popper>
                                </span>
                                <span class="header-script__headline">
                                    {{ sellingPoint.headline }}
                                </span>

                                <p class="header-script__description">
                                    {{ sellingPoint.description }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Media
                        :type="pageHeader.media_type"
                        :inline="true"
                    />
                </div>

                <div
                    ref="script"
                    class="header-script__script"
                >
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import Popper from 'vue3-popper';
import Media from '../../header-components/media/watson-theme-header-media.vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
});

const script = ref(null);

function setInnerHTML(elm, html) {
    elm.innerHTML = html;

    Array.from(elm.querySelectorAll('script')).forEach(oldScriptEl => {
        const newScriptEl = document.createElement('script');

        Array.from(oldScriptEl.attributes).forEach(attr => {
            newScriptEl.setAttribute(attr.name, attr.value);
        });

        const scriptText = document.createTextNode(oldScriptEl.innerHTML);
        newScriptEl.appendChild(scriptText);

        oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
}

onMounted(() => {
    if (props.pageHeader.enable_chameleon) {
        const extraScript = document.createElement('script');
        extraScript.setAttribute('src', '//chameleon-frontend-eu.mvfglobal.com/formLoader.min.js');
        extraScript.setAttribute('async', '');
        document.head.appendChild(extraScript);
    }

    setTimeout(() => {
        setInnerHTML(script.value, props.pageHeader.script);
    }, 750);
});
</script>

<style lang="scss">
@use "watson-theme-header-script";
</style>
