<template>
    <header
        class="relative mb-10 background-header-image"
        :class="[
            pageHeader?.hide_curve === true ? 'background-hide-curve' : ''
        ]"
        :style="`--background-image: url('${backgroundImage}');`"
    >
        <section id="top-header">
            <slot name="top-header"></slot>
        </section>

        <section class="bg-gradient-to-r from-black/10 to-black/25">
            <div class="hidden md:flex bg-gradient-to-r from-black/10 to-black/25">
                <div class="container flex justify-between text-sm text-white">
                    <div>
                        <a
                            v-if="brand.telephone"
                            :href="`tel:${brand.countryCode}${brand.telephone.replace(/\s|-/g, '').replace(/^0+/, '')}`"
                            class="phone-number opacity-70 hover:opacity-100 ease-out duration-300"
                        >
                            {{ brand.telephone }}
                        </a>
                    </div>
                    <div class="space-x-7">
                        <Link
                            v-if="settings.becomeAPartnerUrl"
                            :href="settings.becomeAPartnerUrl"
                            class="hover:opacity-100 ease-out duration-300"
                            :class="[
                                isActive({url: settings.becomeAPartnerUrl}, $page.props.url) ? 'opacity-100 font-bold': 'opacity-70'
                            ]"
                        >
                            {{ settings.translations.header.becomeAPartner }}
                        </Link>
                        <Link
                            v-if="settings.covidUrl"
                            :href="settings.covidUrl"
                            class="hover:opacity-100 ease-out duration-300"
                            :class="[
                                isActive({url: settings.covidUrl}, $page.props.url) ? 'opacity-100 font-bold': 'opacity-70'
                            ]"
                        >
                            {{ settings.translations.header.covid }}
                        </Link>
                    </div>
                </div>
            </div>

            <section>
                <div class="md:container px-4 flex py-3.5 md:py-1.5 w-full justify-between items-center">
                    <div>
                        <Link href="/">
                            <img
                                :src="brand.logo.url"
                                :width="brand.logo?.width > 1 ? brand.logo.width : null"
                                :height="brand.logo?.height > 1 ? brand.logo.height : null"
                                class="h-[2.2rem] md:h-[35px]"
                                :alt="brand.name"
                            >
                        </Link>
                    </div>

                    <Menu />
                </div>
            </section>
        </section>

        <slot></slot>
    </header>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { isActive } from '../../mixins/menu';
import Menu from './Menu-index.vue';

const props = defineProps({
    pageHeader: {
        type: Object,
        required: false,
        default: null,
    },
    backgroundImage: {
        type: [String, Boolean],
        required: false,
        default: null,
    },
});

const page = usePage();

const brand = page.props.brand;
const settings = page.props.settings;

const backgroundImage = computed(() => {
    let backgroundImage = '';

    if (props.backgroundImage) {
        backgroundImage = props.backgroundImage;
    }

    return backgroundImage;
});
</script>

<style lang="scss" scoped>
// this is the gradient for HHQ that I made to look like the image. just here for save keep until its needed
.saved-style {
  background: rgba(85, 200, 232,1);
  background: linear-gradient(122deg, rgba(90, 213, 242,1) 0%, rgba(90, 213, 242,1) 69%,rgba(85, 200, 232,1) 69%, rgba(85, 200, 232,1) 83.5%, rgba(67, 155, 199,1) 83.5%);
}
</style>
