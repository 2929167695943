<template>
    <div class="its-that-easy">
        <div class="its-that-easy__title">
            {{ $page.props.settings.translations.itsThatEasy.title }}
        </div>

        <div class="its-that-easy__usps">
            <div
                v-for="(point, index) in $page.props.settings.thatEasyPoints"
                :key="index"
                class="its-that-easy__usp"
            >
                <div class="its-that-easy__number-container">
                    <div class="its-that-easy__number">
                        {{ index + 1 }}
                    </div>
                </div>

                <div class="its-that-easy__usp-content">
                    <div class="its-that-easy__usp-title">
                        {{ point.title }}
                    </div>

                    {{ point.text }}
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use 'watson-theme-its-that-easy';
</style>
