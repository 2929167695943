<template>
    <div class="cro-form">
        <div class="cro-form__header">
            <h3 class="cro-form__header-cta">
                Answer these simple questions and we will find you the BEST prices
            </h3>
        </div>

        <p class="cro-form__question">
            Which type of solar quotes do you need?
        </p>

        <div class="cro-form__answers">
            <a class="cro-form__button" @click="redirect(396)">
                <span class="cro-form__button-text">SOLAR PANELS + BATTERY</span>
            </a>
            <a class="cro-form__button" @click="redirect(27)">
                <span class="cro-form__button-text">SOLAR PANELS</span>
            </a>
            <a class="cro-form__button" @click="redirect(284)">
                <span class="cro-form__button-text">SOLAR BATTERY</span>
            </a>
        </div>

        <div class="cro-form__progress">
            <div class="cro-form__progress-item">
                <span>1</span>
            </div>
            <div class="cro-form__progress-item cro-form__progress-item--blank">
                <span>2</span>
            </div>
            <div class="cro-form__progress-item cro-form__progress-item--blank">
                <span>3</span>
            </div>
            <div class="cro-form__progress-item cro-form__progress-item--blank">
                <span>4</span>
            </div>
            <div class="cro-form__progress-item cro-form__progress-item--blank">
                <span>5</span>
            </div>
        </div>

        <div class="cro-form__footer">
            <p class="cro-form__footer-text">
                It only takes 30 seconds<br> 100% free with no obligation
            </p>
        </div>
    </div>
</template>

<script setup>

const redirect = id => {
    const url = new URL('https://quotes.greenmatch.co.uk/solar-quotes');
    url.searchParams.append('first_question', id);
    url.searchParams.append('origin', window.location.href);
    window.location.href = url.href;
};
</script>

<style lang="scss" scoped>
@use "watson-theme-cro-form";

</style>
