<template>
    <div class="product-guide">
        <div class="product-guide__item-container">
            <button
                v-for="item in activeProductGroup[0]?.product_group"
                :key="item.product.product_group_item_key"
                class="product-guide__item"
                @click="onClick(item.product)"
            >
                <div class="product-guide__image-container">
                    <img
                        class="product-guide__image"
                        :src="item.product.product_group_item_image.url"
                        :alt="item.product.product_group_item_image.alt"
                        loading="lazy"
                        :width="item.product.product_group_item_image?.width > 1 ? item.product.product_group_item_image.width : null"
                        :height="item.product.product_group_item_image?.height > 1 ? item.product.product_group_item_image.height : null"
                    >
                </div>
                <div class="product-guide__name">
                    {{ item.product.product_group_item_name }}
                </div>
            </button>
        </div>

        <div
            v-if="productGroupHistory.length > 0"
            class="product-guide__back-button-container"
        >
            <button
                class="product-guide__back-button"
                @click="onBack"
            >
                {{ $page.props.settings?.translations?.productGuide?.back || 'Go Back' }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { router } from '@inertiajs/vue3';

const props = defineProps({
    productGuide: {
        type: Object,
        required: false,
        default: null,
    },
});

const activeProductGroupKey = ref('START');
const productGroupHistory = ref([]);
const activeProductGroup = computed(() =>
    props.productGuide.product_groups.filter(product_group => {
        return product_group.product_group_key === activeProductGroupKey.value;
    }),
);

const onClick = product => {
    if (product.product_group_item_destination_type === 'group') {
        productGroupHistory.value.push(activeProductGroupKey.value);
        activeProductGroupKey.value = product.product_group_item_destination_1;
    } else {
        let url = product.product_group_item_destination_2;
        if (product.product_group_item_value && product.product_group_item_value !== '') {
            url += `?start=${product.product_group_item_value}`;
        }

        router.visit(url);
    }
};

const onBack = () => {
    activeProductGroupKey.value = productGroupHistory.value.pop();
};
</script>

<style lang="scss" scoped>
@use "watson-theme-product-guide";

.product-guide {
  --background: v-bind("props.productGuide.ProductGuideBackgroundColor");
  --background-hover: v-bind("props.productGuide.ProductGuideBackgroundColorHover");
}
</style>
