<template>
    <Menu
        as="div"
        class="relative"
        @mouseover="menuOpen = true"
        @mouseleave="menuOpen = false"
    >
        <MenuButton class="group relative ease-out duration-300 py-3 cursor-default">
            <div
                v-if="!item.url || item.url.length === 0"
                class="group-hover:opacity-70"
                :class="{'font-bold': isActive(item, $page.props.url)}"
            >
                <div class="flex items-center">
                    {{ item.name }}

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="ml-1 w-4 h-4"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </div>
            </div>
            <Link
                v-else
                class="group-hover:opacity-70"
                :class="{'font-bold': isActive(item, $page.props.url)}"
                :href="item.url"
            >
                {{ item.name }}
            </Link>
            <div class="absolute flex justify-center inset-x-0 -bottom-2">
                <Transition
                    v-show="menuOpen && item.subMenu"
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <svg
                        v-show="true"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 99.3 37"
                        fill="#ffffff"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M99.3,37H0L46.8,2.2c1.8-1.3,4.2-1.3,5.9,0L99.3,37z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </Transition>
            </div>
        </MenuButton>
        <Transition
            v-if="item.subMenu"
            enter-active-class="transition ease-out duration-200"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                v-show="menuOpen"
                static
                class="absolute z-10 right-0 origin-top-right p-2 whitespace-nowrap rounded bg-white shadow"
            >
                <template
                    v-for="subItem in item.subMenu"
                    :key="subItem.url"
                >
                    <MenuItem v-slot="{ active }">
                        <Link
                            :href="subItem.url"
                            :class="[
                                active ? 'bg-base-400 bg-opacity-10' : '',
                                'block p-2 text-gray-400 rounded',
                                isActive(subItem, $page.props.url) ? 'font-bold': ''
                            ]"
                        >
                            {{ subItem.name }}
                        </Link>
                    </MenuItem>
                    <MenuItem
                        v-for="subSubItem in subItem.subMenu"
                        :key="subSubItem.url"
                        v-slot="{ active }"
                    >
                        <Link
                            :href="subSubItem.url"
                            :class="[
                                active ? 'bg-base-400 bg-opacity-10' : '',
                                'block p-2 text-gray-400 rounded',
                                isActive(subItem, $page.props.url) ? 'font-bold': ''
                            ]"
                            class="flex items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                class="opacity-70 mr-1 w-5 h-5"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>

                            {{ subSubItem.name }}
                        </Link>
                    </MenuItem>
                </template>
            </MenuItems>
        </Transition>
    </Menu>
</template>

<script setup>
import { ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { isActive } from '../../../mixins/menu';

defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const menuOpen = ref(false);

// const isActive = (item) => {
//   if (item.subMenu) {
//     for (const subMenu of item.subMenu) {
//       if (isActive(subMenu)) {
//         return true;
//       }
//     }
//
//     return item.url === $page.props.url;
//   }
//
//   return item.url === $page.props.url;
// };
</script>
